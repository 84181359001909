<template>
  <div>
    <v-snackbar v-model="snackbar" color="#3CB043" right :timeout="3000">
      <v-layout wrap>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="snackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-dialog v-model="dialogAddMember" width="700px" persistent>
          <v-layout wrap px-4 justify-end style="background-color: white">
            <v-flex xs12 text-left align-self-center>
              <v-layout pt-8 wrap justify-center>
                <v-flex xs12 md12 lg12 text-left>
                  <span
                    style="
                      color: #000000;
                      font-family: poppinsbold;
                      font-size: 22px;
                    "
                    >Add member</span
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap py-5 justify-center v-if="userList.length > 0">
                <v-flex xs12
                  ><v-card outlined>
                    <v-layout wrap pa-4>
                      <v-flex
                        xs12
                        sm6
                        pa-2
                        v-for="(user, u) in userList"
                        :key="u"
                      >
                        <v-card outlined>
                          <v-layout wrap py-5>
                            <v-flex xs2 pl-2>
                              <v-avatar size="30" :color="getRandomColor()">
                                <span style="color: #fff">{{
                                  user.name.charAt(0).toUpperCase()
                                }}</span>
                              </v-avatar>
                            </v-flex>
                            <v-flex xs9 pl-3 pt-1>
                              <v-layout
                                wrap
                                justify-center
                                style="line-height: 12px"
                              >
                                <v-flex xs12 text-left>
                                  <span
                                    style="
                                      font-family: poppinsmedium;
                                      font-size: 14px;
                                      color: #000000;
                                    "
                                    >{{ user.name }}</span
                                  >
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span
                                    style="
                                      font-family: poppinsregular;
                                      font-size: 12px;
                                      color: #646464;
                                    "
                                    >{{ user.email }}</span
                                  >
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex
                              xs1
                              align-self-start
                              @click="removeUser(user)"
                            >
                              <v-icon small>mdi-close-circle</v-icon>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex></v-layout
                    >
                  </v-card></v-flex
                >
              </v-layout>
              <v-layout wrap py-4>
                <v-flex xs12 sm6 text-left py-2 pa-2>
                  <span style="font-family: opensansregular; font-size: 14px"
                    >Full name</span
                  >
                  <v-text-field
                    outlined
                    dense
                    v-model="name"
                    :hide-details="true"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm6 text-left py-2 pa-2>
                  <span style="font-family: opensansregular; font-size: 14px"
                    >Email</span
                  >
                  <v-text-field
                    outlined
                    dense
                    v-model="email"
                    :hide-details="true"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm7 text-left pa-2>
                  <span style="font-family: opensansregular; font-size: 14px"
                    >Phone</span
                  >
                  <v-layout wrap>
                    <v-flex xs3 sm3 pt-1>
                      <vue-country-code
                        @onSelect="onSelect"
                        :preferredCountries="['vn', 'us', 'gb']"
                      >
                      </vue-country-code
                    ></v-flex>
                    <v-flex xs9 sm9>
                      <v-text-field
                        ref="phone"
                        color="#717171"
                        placeholder="Mobile number"
                        outlined
                        dense
                        type="number"
                        v-model="phone"
                      >
                        <template v-slot:prepend-inner>
                          <v-layout justify-center>
                            <v-flex pt-0 class="align-self-center">
                              <span
                                style="
                                  font-family: poppinsregular;
                                  font-size: 15px;
                                  color: #000;
                                "
                                >{{ formattedValue }}</span
                              >
                            </v-flex>
                            <v-flex pt-1 pl-2 xs1>
                              <v-divider vertical></v-divider>
                            </v-flex>
                          </v-layout>
                        </template>
                      </v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm5 pa-2>
                  <v-layout wrap justify-start>
                    <v-flex xs12 text-left>
                      <span
                        style="font-family: opensansregular; font-size: 14px"
                        >Gender</span
                      >
                    </v-flex>
                    <v-flex
                      pt-2
                      style="font-family: opensansregular; font-size: 14px"
                    >
                      <v-radio-group
                        v-model="gender"
                        mandatory
                        style="color: #000"
                      >
                        <v-layout wrap>
                          <v-flex>
                            <v-radio
                              color="black"
                              small
                              label="Male"
                              value="Male"
                            ></v-radio>
                          </v-flex>
                          <v-flex>
                            <v-radio
                              color="black"
                              label="Female"
                              value="Female"
                            ></v-radio>
                          </v-flex>
                        </v-layout>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-layout wrap justify-end>
                  <v-flex xs12 sm2 text-right>
                    <v-btn dark color="red" @click="closeDialog()">
                      <span
                        style="
                          text-transform: none;
                          font-family: opensansregular;
                        "
                        >close</span
                      >
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm2 text-right>
                    <v-btn dark color="#3CB043" @click="addMember()">
                      <span
                        style="
                          text-transform: none;
                          font-family: opensansregular;
                        "
                        >Save</span
                      >
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-dialog>
        <v-dialog v-model="dialogExcel" width="700px" persistent>
          <v-layout wrap px-4 justify-end style="background-color: white">
            <v-flex xs11 text-left align-self-center py-10 px-5>
              <span
                style="
                  font-family: poppinsmedium;
                  font-size: 16px;
                  color: #000000;
                "
                >Note : Please upload an Excel file with "Sheet1"
                containing columns; "Name," "Email," "Country Code,"
                "Phone," and "Gender" in the correct format.</span
              >
              <v-layout wrap>
                <v-flex xs12 pt-4>
                  <v-btn
                    block
                    small
                    dark
                    height="50px"
                    color="#3CB043"
                    @click="$refs.showFile.click()"
                  >
                    <v-icon style="color: #ffffff"
                      >mdi-note-text-outline</v-icon
                    >
                    <span style="font-family: poppinssemibold"
                      >UPLOAD STUDENT INFO SHEET</span
                    >
                  </v-btn>
                  <input
                    v-show="false"
                    id="file"
                    ref="showFile"
                    multiple="multiple"
                    type="file"
                    @change="showFile"
                  />
                  <v-layout justify-center wrap pt-2>
                    <v-flex xs12 text-center>
                      <span style="font-family: poppinsregular; color: #8f8f8f"
                        >Upload One or Multiple Student Data</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs1 pt-2 align-self-start @click="dialogExcel = false">
              <v-icon>mdi-close-circle</v-icon>
            </v-flex>
          </v-layout>
        </v-dialog>
        <v-card>
          <v-layout wrap justify-center py-5 v-if="storage.length > 0">
            <v-flex xs11 sm10 text-left>
              <span
                style="
                  font-family: poppinsregular;
                  font-size: 20px;
                  color: #000000;
                "
                >Your Orders ({{ cart.cartLength }})</span
              >
              <v-layout wrap justify-start>
                <v-flex xs12 py-4 v-for="(item, i) in storage" :key="i">
                  <v-layout wrap>
                    <v-flex xs12 pb-2>
                      <span
                        style="
                          font-family: poppinsregular;
                          font-size: 18px;
                          color: #000000;
                        "
                        >Course name</span
                      >
                    </v-flex>
                  </v-layout>
                  <v-card outlined>
                    <v-layout wrap justify-start align-start pa-4>
                      <v-flex xs12 sm4 md1>
                        <v-img
                          contain
                          height="80px"
                          :src="mediaURL + item.courseId.frontImage"
                        ></v-img>
                      </v-flex>
                      <v-flex xs12 sm7 md7 pa-2>
                        <span
                          style="
                            color: #7f7f7f;
                            font-family: poppinsregular;
                            font-size: 13px;
                          "
                          >Courses | {{ item.courseId.category.name }}</span
                        >
                        <br />
                        <span
                          style="
                            font-family: poppinssemibold;
                            font-size: 14px;
                            color: #000000;
                          "
                          >{{ item.courseId.courseName }}</span
                        >
                      </v-flex>
                      <v-flex xs4 sm2 md1 text-right pt-3>
                        <span
                          style="
                            font-family: poppinsregular;
                            font-size: 14px;
                            color: #000000;
                          "
                          >Price</span
                        >
                        <br />
                        <span
                          style="
                            font-family: poppinsmedium;
                            font-size: 14px;
                            color: #000000;
                          "
                          >$ {{ item.courseId.offerPrice }}</span
                        >
                      </v-flex>
                      <v-flex xs4 sm2 md1 text-right pt-3>
                        <span
                          style="
                            font-family: poppinsregular;
                            font-size: 14px;
                            color: #000000;
                          "
                          >Qty</span
                        >
                        <!-- <br /> -->
                        <v-layout wrap justify-center>
                          <v-flex xs10 sm9 text-right>
                            <span
                              style="
                                font-family: poppinsmedium;
                                font-size: 14px;
                                color: #000000;
                              "
                              >{{ item.numberofUsers }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs4 sm2 md1 text-right pt-3>
                        <span
                          style="
                            font-family: poppinsregular;
                            font-size: 14px;
                            color: #000000;
                          "
                          >Total</span
                        >
                        <br />
                        <span
                          style="
                            font-family: poppinsmedium;
                            font-size: 14px;
                            color: #000000;
                          "
                          >$ {{ item.courseTotal }}</span
                        >
                      </v-flex>
                      <v-flex xs2 sm1 md1 text-right @click="removeCart(item)">
                        <v-icon small>mdi-close-circle</v-icon>
                      </v-flex>
                    </v-layout>
                  </v-card>
                  <v-layout wrap pt-5 pb-2>
                    <v-flex xs12>
                      <span
                        style="
                          font-family: poppinsmedium;
                          font-size: 16px;
                          color: #000000;
                        "
                        >Add participants</span
                      >
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      md4
                      py-4
                      pr-4
                      v-for="(list, l) in item.users"
                      :key="l"
                    >
                      <v-card
                        outlined
                        :color="list.level == 0 ? '#e8e8e8' : ''"
                        v-if="list.role != 'Guest'"
                      >
                        <v-layout wrap py-2>
                          <v-flex xs3 sm2 pa-2 v-if="list.level == 0">
                            <v-icon large color="purple"
                              >mdi-account-circle</v-icon
                            >
                          </v-flex>
                          <v-flex xs3 sm2 pa-2 v-else>
                            <v-avatar size="35" :color="getRandomColor()">
                              <span style="color: #fff">{{
                                list.name.charAt(0).toUpperCase()
                              }}</span>
                            </v-avatar>
                          </v-flex>
                          <v-flex xs7 sm9>
                            <span
                              style="
                                font-family: poppinsmedium;
                                font-size: 14px;
                                color: #000000;
                              "
                            >
                              {{ list.name }}
                            </span>
                            <br />
                            <span
                              style="
                                font-family: poppinsmedium;
                                font-size: 13px;
                                color: #676161;
                              "
                              >{{ list.email }}</span
                            >
                          </v-flex>
                          <v-flex xs2 sm1 pt-2>
                            <v-dialog v-model="list.delete" max-width="600px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-on="on" v-bind="attrs"
                                  >mdi-trash-can-outline</v-icon
                                >
                              </template>
                              <v-card v-if="list.level != 0">
                                <v-card-title style="font-family: poppinsmedium"
                                  >Are you sure you want to delete this
                                  Guest?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="red"
                                    text
                                    @click="list.delete = false"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="green"
                                    text
                                    @click="removeUserAdded(list, item)"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                              <v-card v-else>
                                <v-card-title style="font-family: poppinsmedium"
                                  >Are you sure you want to delete
                                  yourself?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="red"
                                    text
                                    @click="list.delete = false"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="green"
                                    text
                                    @click="removeMe(item)"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm8>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          sm6
                          md4
                          pr-xs-0
                          pr-sm-2
                          pr-md-2
                          pr-lg-2
                          pr-xl-2
                        >
                          <v-card color="#EEFFF2" outlined>
                            <v-layout
                              wrap
                              pa-3
                              py-4
                              @click="addMemberCheck(item)"
                            >
                              <v-flex xs1>
                                <v-icon color="#3CB043">mdi-plus-circle</v-icon>
                              </v-flex>
                              <v-flex xs11 sm10 md10 pl-5>
                                <span
                                  style="
                                    font-family: poppinsregular;
                                    font-size: 14px;
                                    color: #000000;
                                  "
                                  >Add member</span
                                >
                              </v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                        <v-flex
                          xs12
                          sm6
                          md2
                          pl-xs-0
                          pl-sm-5
                          pl-md-5
                          pl-lg-5
                          pl-xl-5
                          pt-1
                        >
                          <v-btn
                            color="#3CB043"
                            x-large
                            block
                            @click="(dialogExcel = true), (cartId = item._id)"
                          >
                            <v-layout wrap>
                              <v-flex xs12 py-5>
                                <v-icon color="white">mdi-file-upload</v-icon>
                                &nbsp;
                                <span
                                  style="
                                    font-family: opensansregular;
                                    color: #ffffff;
                                    font-size: 14px;
                                    text-transform: none;
                                  "
                                  >Upload Student Info</span
                                >
                              </v-flex>
                            </v-layout>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-flex xs12 pt-10>
                    <v-divider></v-divider>
                  </v-flex>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-card color="#F5F6F7" outlined>
                    <v-layout wrap>
                      <v-flex
                        xs12
                        px-xs-4
                        px-sm-8
                        px-md-10
                        px-lg-10
                        px-xl-10
                        py-10
                        ><span style="font-family: poppinsbold; font-size: 18px"
                          >ORDER SUMMARY</span
                        >
                        <v-layout wrap pt-5>
                          <v-flex
                            xs12
                            v-for="(sum, s) in cart.summary.courseSummary"
                            :key="s"
                          >
                            <v-layout wrap>
                              <v-flex>
                                <span
                                  style="
                                    font-family: poppinsmedium;
                                    font-size: 14px;
                                  "
                                  >{{ sum.courseName }}
                                  <span style="font-family: poppinsbold"
                                    >(x{{ sum.numberofUsers }})</span
                                  ></span
                                >
                              </v-flex>
                              <v-flex text-right>
                                <span
                                  style="
                                    font-family: poppinsregular;
                                    font-size: 14px;
                                  "
                                  >$ {{ sum.courseTotal }}</span
                                >
                              </v-flex>
                            </v-layout>
                            <v-flex xs12 py-2>
                              <v-divider></v-divider>
                            </v-flex>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap v-if="cart.summary">
                          <v-flex
                            ><span
                              style="
                                font-family: poppinsmedium;
                                font-size: 14px;
                              "
                              >Sub total</span
                            ></v-flex
                          >
                          <v-flex text-right>
                            <span
                              style="
                                font-family: poppinsregular;
                                font-size: 14px;
                              "
                              >$ {{ cart.summary.sumTotal }}</span
                            >
                          </v-flex>
                          <v-flex xs12 py-2>
                            <v-divider></v-divider>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap v-if="cart.summary">
                          <v-flex
                            ><span
                              style="
                                font-family: poppinsmedium;
                                font-size: 14px;
                              "
                              >Tax</span
                            ></v-flex
                          >
                          <v-flex text-right>
                            <span
                              style="
                                font-family: poppinsregular;
                                font-size: 14px;
                              "
                              >$ {{ cart.summary.tax }}</span
                            >
                          </v-flex>
                          <v-flex xs12 py-2>
                            <v-divider></v-divider>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap v-if="cart.summary" pt-4>
                          <v-flex
                            ><span
                              style="
                                font-family: poppinsbold;
                                font-size: 18px;
                                text-transform: uppercase;
                              "
                              >Total cost</span
                            ></v-flex
                          >
                          <v-flex text-right>
                            <span
                              style="font-family: poppinsbold; font-size: 18px"
                              >$ {{ cart.summary.cartTotal }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap py-5>
                <v-flex @click="goBack()">
                  <span
                    style="
                      color: #565656;
                      font-family: poppinsmedium;
                      text-transform: uppercase;
                      text-decoration: underline;
                      font-size: 14px;
                      cursor: pointer;
                    "
                    >continue back TO COURSES</span
                  >
                </v-flex>
                <v-flex text-right>
                  <v-btn color="#3CB043" @click="checkOut()">
                    <span
                      style="
                        font-family: poppinsregular;
                        color: #ffffff;
                        text-transform: none;
                        font-size: 13px;
                      "
                      >Proceed to payment</span
                    >
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage", "cart"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      subId: "",
      courses: [],
      guestid: "",
      idExcel: "",
      dialogAddMember: false,
      dialogExcel: false,
      filePreview: false,
      showFilePreview: false,
      file: null,
      snackbar: false,
      msg: null,
      cartId: "",
      value: "",
      user: "",
      userList: [],
      phone: "",
      gender: "",
      name: "",
      email: "",
      link: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      phnoRules: [
        (v) => !!v || "Phone is required",
        (v) =>
          /[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/.test(v) ||
          "Phone is invalid",
      ],
    };
  },
  computed: {
    appLogin() {
      if (this.$store.state.isLoggedIn) return this.$store.state.isLoggedIn;
      else return null;
    },
    formattedValue() {
      return "+" + this.value;
    },
  },
  beforeMount() {
    // this.getData();
  },

  methods: {
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
      this.value = dialCode;
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/guest/list",
        method: "POST",
        data: {
          cartId: this.cartId,
        },
        headers: { token: localStorage.getItem("token") },
      })
        .then((response) => {
          this.userList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addMemberCheck(id) {
      if (!this.$store.state.isLoggedIn) {
        this.$router.push("/loginForm");
        return;
      } else {
        this.dialogAddMember = true;
        this.cartId = id._id;
        if (this.cartId) this.getData();
      }
    },
    removeCart(id) {
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else this.guestid = localStorage.getItem("userId");
      axios({
        url: "/cart/remove",
        method: "POST",
        data: {
          guestid: this.guestid,
          cartId: id._id,
        },
        headers: headers1,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.snackbar = true;
            this.$store.commit("changeCart", response.data.cartLength);
            this.$emit("stepper", {
              response: response.data,
              getData: true,
            });
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addMember() {
      this.appLoading = true;
      axios({
        url: "/add/guest/user",
        method: "POST",
        data: {
          cartId: this.cartId,
          name: this.name,
          phone: this.phone,
          email: this.email,
          gender: this.gender,
          countryCode: this.formattedValue,
        },
        headers: { token: localStorage.getItem("token") },
      })
        .then((response) => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.snackbar = true;
          this.getData();
          (this.name = ""), (this.phone = ""), (this.email = "");
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeUser(id) {
      axios({
        url: "/guest/remove/course",
        method: "POST",
        data: {
          cartId: this.cartId,
          id: id._id,
        },
        headers: { token: localStorage.getItem("token") },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.snackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeUserAdded(userId, cartId) {
      axios({
        url: "/guest/remove/course",
        method: "POST",
        data: {
          cartId: cartId._id,
          id: userId._id,
        },
        headers: { token: localStorage.getItem("token") },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.snackbar = true;
            this.$emit("stepper", {
              response: response.data,
              getData: true,
            });
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    removeMe(cartId) {
      axios({
        url: "/cart/remove/me",
        method: "POST",
        data: {
          cartId: cartId._id,
        },
        headers: { token: localStorage.getItem("token") },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.snackbar = true;
            this.$emit("stepper", {
              response: response.data,
              getData: true,
            });
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    closeDialog() {
      this.dialogAddMember = false;
      this.$emit("stepper", {
        getData: true,
      });
    },
    goBack() {
      this.$router.go(-2);
    },
    checkOut() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/payment/start",
        data: {
          totalAmount: this.cart.summary.cartTotal,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // window.id = response.data.paymentdata._id;
            // window.orderid = response.data.data.order_id;
            this.link = response.data.url;
            window.location.href = this.link;
            //  this.msg = response.data.msg;
            //   this.snackbar = true;
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    showFile(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.filePreview = e.target.result;
      };
      if (this.file.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showsnackbar = true;
        return;
      }
      this.showFilePreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
        this.uploadFile();
      }
    },
    uploadFile() {
      let formData = new FormData();
      formData.append("excelFile", this.file);
      formData.append("cartId", this.cartId);
      axios({
        method: "POST",
        url: "/upload/excel",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Uploaded Successully";
            this.snackbar = true;
            this.dialogExcel = false;
            this.file = null;
            this.filePreview = null;
            this.showFilePreview = null;
            this.$emit("stepper", {
              response: response.data,
              getData: true,
            });
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.snackbar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
